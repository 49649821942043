<template>
  <div>
    <app-bar />
    <router-view />
  </div>
</template>

<script>
import AppBar from '@/components/AppBar'
export default {
  name: 'MainView',
  components: {
    AppBar
  },
  data: () => ({})
}
</script>
